import EricImage from "../Assets/AboutUs/Eric.png";

export const ManagementTeamList = [
    {
        name: "Eric WONG, CEO",
        image: EricImage,
        description: ["MSE, University of Michigan, Ann Arbor",
            "MBA, University of Purdue, West-Lafayette",],
    },
]
