
import DarwinImage from "../Assets/AboutUs/Darwin.png";
import JimmyImage from "../Assets/AboutUs/Jimmy.png";
import AnthonyImage from "../Assets/AboutUs/Anthony.png";

export const FounderList = [
    {
        name: "Prof. Darwin Tat Ming LAU 劉達銘​",
        image: DarwinImage,
        description: [
            "Assistant Dean, Faculty of Engineering", "Associate Professor, Department of Mechanical and Automation Engineering", "CUHK"],
    },
    {
        name: "Prof. Jimmy Ho Man LEE  李浩文​",
        image: JimmyImage,
        description: [
            "Director, University Planning Office", "Professor, Department of Computer Science and Engineering", "CUHK"],
    },
    {
        name: "Dr. Anthony K.W. SUM 岑國榮​",
        image: AnthonyImage,
        description: ["Lecturer, Department of Computer Science and Engineering", "CUHK"],
    },
]
